import React from 'react';
import GetTestStatus from '../GetTestStatus';
import { CiCircleCheck } from 'react-icons/ci';
import { BsExclamationCircle } from 'react-icons/bs';
import { CCard, CCardBody, CCardTitle, CCardText, CCol, CRow, CButton } from '@coreui/react';
import {PiFlaskLight} from "react-icons/pi";

interface SolventReferenceProps {
	latestSolventReference: {
		old: boolean;
		dated: string;
	};
	handleScanHardware: () => void;
}

const SolventReference: React.FC<SolventReferenceProps> = ({ latestSolventReference, handleScanHardware }) => (
	<CCard style={{backgroundColor: "#DAEEF5"}}>
		<CCardBody>
			<CCardTitle>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "150px",
					}}
				>
					<PiFlaskLight
						style={{
							color: "#1488f5",
							height: "150px",
						}}
						size="100"
					/>
				</div>
				<h3
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					Solvent Reference
				</h3>
			</CCardTitle>
			
			<CCardText>
				<CRow
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<CCol
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
							marginTop: "2.8rem",
						}}
					>
						<CRow>
							<small style={{color: "grey"}}>Status</small>
						</CRow>
						
						<CRow>
							<small
								style={{
									fontFamily: "Expansiva, sans-serif",
									display: "flex",
									flexDirection: "row",
								}}
							>
								<GetTestStatus test={!latestSolventReference?.old}/>
								{!latestSolventReference?.old
									? "Active"
									: "Inactive"}
							</small>
						</CRow>
					</CCol>
					<CCol
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
							marginTop: "2.8rem",
						}}
					>
						<CRow>
							<small style={{color: "grey"}}>Previous Date</small>
						</CRow>
						
						<CRow>
							<small style={{fontFamily: "Expansiva, sans-serif"}}>
								{latestSolventReference?.dated
									.replace(/-/g, ".")
									.slice(0, 10)}
							</small>
						</CRow>
					</CCol>
				</CRow>
			</CCardText>
			<div
				style={{
					width: "100%",
					display: "flex",
					justifyContent: "center",
				}}
			>
				<CButton
					className="centeralized no-margin"
					style={{
						backgroundColor: latestSolventReference?.old ? "red" : undefined,
					}}
					color="primary"
					onClick={handleScanHardware}
				>
					{latestSolventReference?.old ? (
						<BsExclamationCircle
							style={{marginRight: "5px"}}
							size="20"
						/>
					) : (
						<CiCircleCheck data-testid={'ci-circle-check-icon'} style={{marginRight: "5px"}} size="20"/>
					)}
					{!latestSolventReference.old ? 'Completed' : 'Take a Solvent Reference'}
				</CButton>
			</div>
		</CCardBody>
	</CCard>
);

export default SolventReference;
