import React from 'react'
import {
	CDropdown,
	CDropdownHeader,
	CDropdownItem,
	CDropdownMenu,
	CDropdownToggle,
} from '@coreui/react'
import {
	cilBell,
	cilCreditCard,
	cilSettings,
	cilUser,
	cilSearch,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {useNavigate} from 'react-router-dom';

interface AppHeaderDropdownProps {
	logout: () => void;
}
const AppHeaderDropdown: React.FC<AppHeaderDropdownProps> = ({ logout }) => {
	
	let navigate = useNavigate();
	const handleLogout = () => {
		// Call the logout function when the "Settings" option is clicked
		logout()
	}
	
	return (
		<CDropdown variant="nav-item">
			<CDropdownToggle className="py-0" caret={false}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<div style={{display: 'flex', flexDirection: 'column'}}>
						<p style={{marginBottom: '0px'}}>
							<b>{localStorage.getItem('UserName')}</b>
						</p>
						<p style={{marginBottom: '0px'}}>{localStorage.getItem('UserEmail')}</p>
					</div>
					{/* <CAvatar src={avatar8} size="md" style={{width:"45px",height:"30px"}}/> */}
					<svg xmlns="http://www.w3.org/2000/svg" width="0.9em" height="1em" viewBox="0 0 630 700">
						<path fill="currentColor" d="M622 106L311 417L0 106l65-65l246 245L556 41z"/>
					</svg>
				</div>
			</CDropdownToggle>
			<CDropdownMenu className="pt-0">
				<CDropdownHeader className="bg-light fw-semibold py-2">Account</CDropdownHeader>
				<CDropdownItem
					onClick={() => {
						navigate('/Settings')
					}}
					className="poniter"
				>
					<CIcon icon={cilSettings} className="me-2"/>
					Settings
				</CDropdownItem>
				<CDropdownItem
					className="poniter"
					onClick={() => {
						navigate('/Checkout')
					}}
				>
					<CIcon icon={cilCreditCard} className="me-2"/>
					Billing
				</CDropdownItem>
				<CDropdownItem className="onlyMobile poniter">
					<CIcon icon={cilBell} className="me-2"/>
					Notifications
				</CDropdownItem>
				<CDropdownItem className="onlyMobile pointer">
					<CIcon icon={cilSearch} className="me-2"/>
					Search
				</CDropdownItem>
				<CDropdownItem onClick={handleLogout} className="poniter">
					<CIcon icon={cilUser} className="me-2"/>
					Logout
				</CDropdownItem>
			</CDropdownMenu>
		</CDropdown>
	)
}

export default AppHeaderDropdown